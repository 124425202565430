import { createContext, useState } from 'react'
import React from 'react'

const AlmaDeSamana = createContext()





const AlmaDeSamanaData = ({ children }) => {
  const [AlmaDeSamanaData, setAlmaDeSamanaData] = useState()
  const [bannerEdit,setBannerEdit]=useState({})

  return (
    <AlmaDeSamana.Provider value={{ AlmaDeSamanaData, setAlmaDeSamanaData,bannerEdit,setBannerEdit }}>
      {children}
    </AlmaDeSamana.Provider>
  )
}

export default AlmaDeSamanaData
export { AlmaDeSamana }
