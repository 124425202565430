// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-admin-table {
    font-size: 14px;
}
.common-admin-table thead th {
    white-space: nowrap;
}
.common-admin-table tbody td div.w-100 {
    width: 200px !important;
    height: 200px !important;
}
.common-admin-table tbody td div.w-100 img {
    object-fit: contain;
}
.common-admin-table tbody td div {
    width: 500px !important;
}
.common-admin-table tbody td div.table-heading {
    width: 200px !important;
}`, "",{"version":3,"sources":["webpack://./src/layout/main.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,uBAAuB;IACvB,wBAAwB;AAC5B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".common-admin-table {\n    font-size: 14px;\n}\n.common-admin-table thead th {\n    white-space: nowrap;\n}\n.common-admin-table tbody td div.w-100 {\n    width: 200px !important;\n    height: 200px !important;\n}\n.common-admin-table tbody td div.w-100 img {\n    object-fit: contain;\n}\n.common-admin-table tbody td div {\n    width: 500px !important;\n}\n.common-admin-table tbody td div.table-heading {\n    width: 200px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
